exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-career-form-tsx": () => import("./../../../src/pages/career/form.tsx" /* webpackChunkName: "component---src-pages-career-form-tsx" */),
  "component---src-pages-career-index-tsx": () => import("./../../../src/pages/career/index.tsx" /* webpackChunkName: "component---src-pages-career-index-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-seaseed-tsx": () => import("./../../../src/pages/seaseed.tsx" /* webpackChunkName: "component---src-pages-seaseed-tsx" */),
  "component---src-pages-solutions-banking-tsx": () => import("./../../../src/pages/solutions/banking.tsx" /* webpackChunkName: "component---src-pages-solutions-banking-tsx" */),
  "component---src-pages-solutions-index-tsx": () => import("./../../../src/pages/solutions/index.tsx" /* webpackChunkName: "component---src-pages-solutions-index-tsx" */),
  "component---src-pages-solutions-supply-chain-tsx": () => import("./../../../src/pages/solutions/supply-chain.tsx" /* webpackChunkName: "component---src-pages-solutions-supply-chain-tsx" */),
  "component---src-templates-blog-post-category-tsx": () => import("./../../../src/templates/blog-post-category.tsx" /* webpackChunkName: "component---src-templates-blog-post-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-search-tsx": () => import("./../../../src/templates/blog-search.tsx" /* webpackChunkName: "component---src-templates-blog-search-tsx" */),
  "component---src-templates-career-detail-tsx": () => import("./../../../src/templates/career-detail.tsx" /* webpackChunkName: "component---src-templates-career-detail-tsx" */),
  "component---src-templates-research-post-tsx": () => import("./../../../src/templates/research-post.tsx" /* webpackChunkName: "component---src-templates-research-post-tsx" */)
}

